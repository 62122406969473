$(".slider").slick({
  arrows: false, //左右の矢印はなし
  autoplay: true, //自動的に動き出すか。初期値はfalse。
  autoplaySpeed: 0, //自動的に動き出す待ち時間。初期値は3000ですが今回の見せ方では0
  speed: 6900, //スライドのスピード。初期値は300。
  infinite: true, //スライドをループさせるかどうか。初期値はtrue。
  pauseOnHover: false, //オンマウスでスライドを一時停止させるかどうか。初期値はtrue。
  pauseOnFocus: false, //フォーカスした際にスライドを一時停止させるかどうか。初期値はtrue。
  cssEase: "linear", //動き方。初期値はeaseですが、スムースな動きで見せたいのでlinear
  slidesToShow: 8, //スライドを画面に7枚見せる
  slidesToScroll: 2, //1回のスライドで動かす要素数
  responsive: [
    {
      breakpoint: 1367,
      settings: {
        slidesToShow: 7,
      },
    },
    {
      breakpoint: 1201,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1001,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 541, //モニターの横幅が426px以下の見せ方
      settings: {
        slidesToShow: 2.25, //スライドを画面に1.5枚見せる
      },
    },
    {
      breakpoint: 359, //モニターの横幅が426px以下の見せ方
      settings: {
        slidesToShow: 1, //スライドを画面に1.5枚見せる
      },
    },
  ],
});

// function FixedAnime() {
//   var headerH = $(".header").outerHeight(true);
//   var scroll = $(window).scrollTop();
//   if (scroll >= headerH) {
//     //headerの高さ以上になったら
//     $(".header").addClass("fixed"); //fixedというクラス名を付与
//   } else {
//     //それ以外は
//     $(".header").removeClass("fixed"); //fixedというクラス名を除去
//   }
// }

// // 画面をスクロールをしたら動かしたい場合の記述
// $(window).scroll(function () {
//   FixedAnime(); /* スクロール途中からヘッダーを出現させる関数を呼ぶ*/
// });

// // ページが読み込まれたらすぐに動かしたい場合の記述
// $(window).on("load", function () {
//   FixedAnime(); /* スクロール途中からヘッダーを出現させる関数を呼ぶ*/
// });

$(function () {
  $(".Collapsible").hide();
  $(".Faqwindow").click(function () {
    $(this).toggleClass("open", 200);
    $(this).next().slideToggle();
  });
});

$(".openbtn").click(function () {
  //ボタンがクリックされたら
  $(this).toggleClass("active"); //ボタン自身に activeクラスを付与し
  $("#g-nav").toggleClass("panelactive"); //ナビゲーションにpanelactiveクラスを付与
});

$("#g-nav a").click(function () {
  //ナビゲーションのリンクがクリックされたら
  $(".openbtn").removeClass("active"); //ボタンの activeクラスを除去し
  $("#g-nav").removeClass("panelactive"); //ナビゲーションのpanelactiveクラスも除去
});
